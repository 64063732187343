.timetable .timetable_header {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  color: #fff;
  background-color: #354f9b;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr auto auto auto;
  justify-content: space-between;
  display: grid;
}

.timetable .timetable_header .timetable_header_route_name {
  grid-area: 1 / 1 / 2 / 2;
  padding-top: 5pt;
  padding-left: 9pt;
  font-size: 21pt;
  font-weight: bold;
  line-height: 110%;
}

.timetable .timetable_header .timetable_header_description {
  grid-area: 2 / 1 / 3 / 2;
  padding-bottom: 5pt;
  padding-left: 9pt;
  line-height: 130%;
}

.timetable .timetable_header .timetable_header_description .timetable_header_description_minutes {
  font-size: 17pt;
  font-weight: bold;
}

.timetable .timetable_header .timetable_header_usage_guide {
  display: inherit;
  grid-area: 1 / 2 / 3 / 3;
  width: 520px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.timetable .timetable_header .timetable_header_qr_description {
  grid-area: 1 / 3 / 3 / 4;
  padding: 0 5pt;
  font-size: 16pt;
  font-weight: bold;
  line-height: 17pt;
}

.timetable .timetable_header .timetable_header_qr_description .center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.timetable .timetable_header .timetable_header_qr {
  display: inherit;
  background-color: #fff;
  grid-area: 1 / 4 / 3 / 5;
}

.timetable .timetable_header .timetable_header_qr canvas {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.timetable .timetable_footer {
  text-align: center;
  color: #fff;
  background-color: #354f9b;
  padding: 3pt 9pt;
}

/*# sourceMappingURL=index.3b55b4fc.css.map */
