.timetable {
  .timetable_header {
    display: grid;
    grid-template-columns: 1fr auto auto auto;
    grid-template-rows: auto auto;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    justify-content: space-between;

    background-color: #354f9b;
    color: #fff;

    .timetable_header_route_name {
      grid-area: 1 / 1 / 2 / 2;

      padding-top: 5pt;
      padding-left: 9pt;

      font-size: 21pt;
      font-weight: bold;
      line-height: 110%;
    }

    .timetable_header_description {
      grid-area: 2 / 1 / 3 / 2;

      padding-left: 9pt;
      padding-bottom: 5pt;
      line-height: 130%;

      .timetable_header_description_minutes {
        font-weight: bold;
        font-size: 17pt;
      }
    }

    .timetable_header_usage_guide {
      grid-area: 1 / 2 / 3 / 3;

      display: inherit;

      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: 520px;
    }

    .timetable_header_qr_description {
      grid-area: 1 / 3 / 3 / 4;

      padding: 0 5pt;

      font-weight: bold;
      font-size: 16pt;

      line-height: 17pt;

      .center {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .timetable_header_qr {
      grid-area: 1 / 4 / 3 / 5;

      display: inherit;

      background-color: #fff;

      canvas {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .timetable_footer {
    padding: 3pt 9pt;

    text-align: center;

    background-color: #354f9b;

    color: #fff;
  }
}
